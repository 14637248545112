import Vue from 'vue/dist/vue.esm'
import CreateAddyAccount from 'components/addyplus/signup/app.vue'

document.addEventListener('turbolinks:load', () => {
    const container = 'create-addy-account-container';
    if (document.getElementById(container)){
        const addyAccountSignup = new Vue({
            el: `#${container}`,
            components: {CreateAddyAccount}
        })
    }
})
