import Vue from 'vue/dist/vue.esm'
import Fileupload from 'components/fileupload/fileupload.vue'

document.addEventListener('turbolinks:load', () => {
  const container = document.getElementById('manager-csr-upload');

  if(container) {
    new Vue({
      el: container,
      components: {Fileupload}
    });
  }
});
