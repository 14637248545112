import Vue from 'vue/dist/vue.esm'

document.addEventListener('turbolinks:load', () => {
    const container = 'admin-nav';

    if(document.getElementById(container)){
        const navMobileMenu = new Vue({
            el: `#${container}`,
            data(){
                return {
                    isOpen: false
                }
            }
        })
    }
})
