<template>
    <div class="modal" v-bind:class="{'is-active' : modalShow}">
        <div class="modal-background"/>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    {{ title }}
                </p>
                <button class="delete"
                        aria-label="close" @click.prevent="hide()"/>
            </header>
            <section class="modal-card-body">
                <slot/>
            </section>
        </div>
    </div>
</template>

<script>

  export default {
    props: ['title'],
    data() {
      return {modalShow: false};
    },
    methods: {
      show() {
        this.modalShow = true;
      },
      hide() {
        this.modalShow = false;
        this.$emit('hide')
      },
      close() {
        this.hide()
      }
    },
    template: `

  `,
  };
</script>
