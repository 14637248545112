import _ from 'lodash';

let CanadianBankNames = _.sortBy([
    "B2B Bank",
    "Bank of Montreal",
    "Bank of Nova Scotia",
    "Bridgewater Bank",
    "Canadian Imperial Bank of Commerce",
    "Canadian Tire Bank",
    "Canadian Western Bank",
    "Citizens Bank of Canada",
    "Street Capital Bank of Canada",
    "Continental Bank of Canada",
    "Concentra Bank",
    "CS Alterna Bank",
    "DirectCash Bank",
    "Equitable Bank",
    "Exchange Bank of Canada",
    "First Nations Bank of Canada",
    "General Bank of Canada",
    "Hollis Canadian Bank",
    "Home Bank",
    "HomEquity Bank",
    "Laurentian Bank of Canada",
    "Manulife Bank of Canada",
    "National Bank of Canada",
    "President's Choice Bank",
    "Rogers Bank",
    "Royal Bank of Canada",
    "Tangerine Bank",
    "Toronto-Dominion Bank",
    "VersaBank",
    "Wealth One Bank of Canada",
    "Zag Bank",
    "AMEX Bank of Canada",
    "Bank of America Canada",
    "Bank of China (Canada)",
    "Bank of Tokyo-Mitsubishi UFJ (Canada)",
    "Bank One Canada",
    "BNP Paribas (Canada)",
    "BofA Canada Bank",
    "Citco Bank Canada",
    "Citibank Canada	 USA",
    "CTBC Bank Corp. (Canada)",
    "Habib Canadian Bank",
    "HSBC Bank Canada",
    "ICICI Bank Canada",
    "Industrial and Commercial Bank of China (Canada)",
    "J.P. Morgan Bank Canada",
    "J.P. Morgan Canada",
    "KEB Hana Bank Canada",
    "Mega International Commercial Bank (Canada)",
    "Shinhan Bank Canada",
    "Société Générale Capital Canada",
    "State Bank of India (Canada)",
    "Sumitomo Mitsui Banking Corporation of Canada",
    "UBS Bank (Canada)",
    "Walmart Canada Bank",
    "Vancity",
    "Coast Capital Savings",
    "Servus Credit Union",
    "Meridian Credit Union",
    "First West Credit Union",
    "Conexus Credit Union",
    "Steinbach Credit Union",
    "Affinity Credit Union",
    "Caisse populaire acadienne ltée (UNI)",
    "Assiniboine Credit Union",
    "Libro Credit Union",
    "Cambrian Credit Union",
    "Connect First Credit Union",
    "Innovation Credit Union"]);

export default CanadianBankNames;
