<template>
  <form>
    <div class="title">Edit Certificate</div>
    <div class="subtitle">{{ registrationName }}</div>
    <b-field label="Property">
      <b-select placeholder="Select a property" v-model="entityAssetId">
        <option
          v-for="entityAsset in entityAssets"
          :value="entityAsset.id"
          :key="entityAsset.id">{{ entityAsset.name }}</option>
      </b-select>
    </b-field>
    <b-field label="Date Issued">
      <b-datepicker v-model="issuedAt"></b-datepicker>
    </b-field>
    <b-field label="Date Cancelled">
      <b-datepicker v-model="cancelledAt"></b-datepicker>
    </b-field>
    <b-field label="Registration Name">
      <b-input v-model="registrationName" type="text"></b-input>
    </b-field>
    <b-field label="First Name">
      <b-input v-model="firstName" type="text"></b-input>
    </b-field>
    <b-field label="Last Name">
      <b-input v-model="lastName" type="text"></b-input>
    </b-field>
    <b-field label="Street Address">
      <b-input v-model="streetAddress" type="text"></b-input>
    </b-field>
    <b-field label="Locality">
      <b-input v-model="locality" type="text"></b-input>
    </b-field>
    <b-field label="Region">
      <b-input v-model="region" type="text"></b-input>
    </b-field>
    <b-field label="Country">
      <b-input v-model="country" type="text"></b-input>
    </b-field>
    <b-field label="Postal Code">
      <b-input v-model="postalCode" type="text"></b-input>
    </b-field>
    <b-field label="Email">
      <b-input v-model="email" type="email"></b-input>
    </b-field>
    <b-field label="Number of Shares">
      <b-input v-model="unitsQuantity" type="number"></b-input>
    </b-field>
    <b-field label="Class &amp; Kind">
      <b-input v-model="shareKind" type="text"></b-input>
    </b-field>
    <b-field label="Acquired By">
      <b-input v-model="allotment" type="text"></b-input>
    </b-field>
    <b-field label="Transfer From">
      <b-input v-model="transferredFrom" type="text"></b-input>
    </b-field>
    <b-field label="Certificate Number">
      <b-input v-model="serial" type="number" disabled></b-input>
    </b-field>
    <b-field label="Agreed Per Share">
      <b-input v-model="agreedPerUnit" type="number"></b-input>
    </b-field>
    <b-field label="Paid Per Share">
      <b-input v-model="cashPerUnit" type="number"></b-input>
    </b-field>
    <b-field label="Paid (not cash)">
      <b-input v-model="otherPerUnit" type="text"></b-input>
    </b-field>
    <b-field label="Cancellation Details">
      <b-input v-model="cancellationDetails" type="text"></b-input>
    </b-field>

    <b-button tag="input"
              native-type="submit"
              @click.prevent="submit"
              value="Save" />
  </form>
</template>

<script>
import Axios from '../../../utils/axios';

export default {
  props: ['accountId', 'legalEntityId', 'csrId'],
  data: function() {
    return {
      entityAssets: [],
      entityAssetId: null,
      issuedAt: null,
      cancelledAt: null,
      registrationName: '',
      firstName: '',
      lastName: '',
      streetAddress: '',
      locality: '',
      region: '',
      country: '',
      postalCode: '',
      email: '',
      unitsQuantity: 0,
      shareKind: '',
      allotment: '',
      transferredFrom: '',
      serial: 0,
      agreedPerUnit: 0,
      cashPerUnit: 0,
      otherPerUnit: '',
      cancellationDetails: '',
    };
  },
  created() {
    if (this.csrId) this.getCsr();
    Axios.get(this.propertiesPath).then((response) => this.entityAssets = response.data);
  },
  computed: {
    collectionPath() {
      return `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/csr`;
    },
    resourcePath() {
      return `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/csr/${this.csrId}`;
    },
    propertiesPath() {
      return `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/entity_assets`;
    },
  },
  methods: {
    getCsr() {
      const {
        issuedAt,
        cancelledAt,
        registrationName,
        firstName,
        lastName,
        streetAddress,
        locality,
        region,
        country,
        postalCode,
        email,
        unitsQuantity,
        agreedPerUnit,
        cashPerUnit,
        shareKind,
        allotment,
        transferredFrom,
        serial,
        otherPerUnit,
        cancellationDetails,
      } = this.$parent.csr;

      if (issuedAt) this.issuedAt = new Date(issuedAt);
      if (cancelledAt) this.cancelledAt = new Date(cancelledAt);

      this.serial = serial;
      this.unitsQuantity = unitsQuantity;
      this.agreedPerUnit = agreedPerUnit;
      this.cashPerUnit = cashPerUnit;
      this.registrationName = registrationName;
      this.firstName = firstName;
      this.lastName = lastName;
      this.streetAddress = streetAddress;
      this.locality = locality;
      this.region = region;
      this.country = country;
      this.postalCode = postalCode;
      this.email = email;
      this.shareKind = shareKind;
      this.allotment = allotment;
      this.transferredFrom = transferredFrom;
      this.otherPerUnit = otherPerUnit;
      this.cancellationDetails = cancellationDetails;
    },
    csrPayload: function() {
      return {
        csr: {
          issuedAt: this.issuedAt,
          cancelledAt: this.cancelledAt,
          serial: this.serial,
          unitsQuantityCents: this.unitsQuantity,
          agreedPerUnitCents: this.agreedPerUnit,
          cashPerUnitCents: this.cashPerUnit,
          entityAssetId: this.entityAssetId,
          meta: {
            email: this.email,
            region: this.region,
            country: this.country,
            locality: this.locality,
            allotment: this.allotment,
            lastName: this.lastName,
            firstName: this.firstName,
            shareKind: this.shareKind,
            postalCode: this.postalCode,
            otherPerUnit: this.otherPerUnit,
            streetAddress: this.streetAddress,
            transferredFrom: this.transferredFrom,
            registrationName: this.registrationName,
            cancellationDetails: this.cancellationDetails,
          },
        },
      };
    },
    submit: function() {
      const redirect = function() {
        window.location.replace(this.collectionPath);
      }.bind(this);

      if (this.csrId) {
        Axios.put(this.resourcePath, this.csrPayload()).then(redirect);
      } else {
        Axios.post(this.collectionPath, this.csrPayload()).then(redirect);
      }
    },
  },
};
</script>
