import Vue from 'vue/dist/vue.esm';
import TenantForm from '../../../../components/manager/properties/tenants/tenant_form';
import ContactBuilder from '../../../../components/contacts/contact_builder'

document.addEventListener('turbolinks:load', () => {
  const container = document.getElementById('tenant-form');

  if(container) {
    new Vue({
      el: container,
      components: {TenantForm,ContactBuilder}
    });
  }
});
