import Vue from 'vue/dist/vue.esm';
import DateTimeFormatter from 'utils/date-time-formatter';
import _ from 'lodash';
import addyData from 'utils/addy-data';

const container = 'property-update-form-container';

addyData.registerForLoadEvent('turbolinks:load', container);

document.addEventListener('addy-data:load', () => {
  if (document.getElementById(container)) {
    const activityEntryData = addyData.fetchData(container)['activityEntry'];

    if (activityEntryData.entryAt) {
      activityEntryData.entryAt = new Date(activityEntryData.entryAt);
    }

    new Vue({
      el: `#${container}`,
      mixins: [DateTimeFormatter],
      data: Object.assign({
        updateTypes: [
          {value: 'bill', label: 'Bill'},
          {value: 'community', label: 'Community'},
          {value: 'event', label: 'Event'},
          {value: 'insurance', label: 'Insurance'},
          {value: 'maintenance', label: 'Maintenance'},
          {value: 'rent_bounced', label: 'Rent Bounced'},
          {value: 'rent_paid', label: 'Rent Paid'},
          {value: 'taxes', label: 'Taxes'},
          {value: 'vacancy', label: 'Vacancy'},
        ],
        entryUpload: null,
      }, activityEntryData),
      methods: {
        initFilename() {
          activityEntryData.filename ? this.entryUpload = {'name': activityEntryData.filename} : this.entryUpload = {'name': 'No File Uploaded'};
        },
      },
      created() {
        this.initFilename();
      },
    });
  }
});
