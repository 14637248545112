<template>
  <div class="csr-wrapper">
    <section class="checkboxes">
      <span v-for="(column, index) in checkboxList"
        :key="index"
        class="control">
        <b-checkbox v-model="column.visible">{{ column.label }}</b-checkbox>
      </span>
      <b-checkbox v-model="selectAll" @input="toggleSelectAll">Select All</b-checkbox>
      <b-checkbox v-model="unselectAll" @input="toggleUnselectAll">Unselect All</b-checkbox>
    </section>
    <section class="table">
      <div class="action-bar" v-show="showActionBar">
        <span class="total-checked">{{ checkedRows.length }}</span>
        <b-tooltip label="Invite" type="is-dark">
          <span class="icon-wrap" @click="openInviteModal">
            <b-icon
              pack="fas"
              icon="user-plus"
              size="is-medium">
            </b-icon>
          </span>
        </b-tooltip>
      </div>
      <b-table :data="tableData" :checked-rows.sync="checkedRows" :sticky-header="true" checkbox-position="left" checkable striped hoverable>
        <b-table-column v-for="(column, index) in checkboxList"
          :key="index"
          :label="column.label"
          :visible="column.visible"
          header-class="no-wrap"
          cell-class="no-wrap"
          v-slot="props">
          {{ props.row ? props.row[column.field] : '' }}
        </b-table-column>
      </b-table>
    </section>
    <b-modal
      v-model="showInviteModal"
      :can-cancel="[true, true, false]"
      :width="550"
      aria-role="dialog"
      aria-modal>
      <div class="card">
        <div @click="showInviteModal = false" v-if="inviteModal.buttonText === 'INVITE'" class="px-2 py-1">
          <i class="fas fa-times has-text-grey-lighter is-size-6 is-pulled-right"></i>
        </div>
        <div class="content-wrap has-text-centered px-5 py-5">
          <div class="is-size-4">{{ inviteModal.content }}</div>
          <div class="is-size-5 py-1">{{ inviteModal.contentNotes }}</div>
          <b-button class="mt-3" type="is-primary" rounded @click="buttonAction">{{ inviteModal.buttonText }}</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Formatter from 'utils/date-time-formatter';
import axios from 'utils/axios';

export default {
  props: {
    certificates: {
      type: Array,
      required: true,
    },
    legalEntityName: {
      type: String,
      required: true,
    },
    legalEntityId: {
      type: String,
      required: true,
    },
    accountId: {
      type: String,
      required: true,
    },
  },
  data: function() {
    return {
      checkboxList: [
        {
          label: 'Date Issued',
          field: 'issuedAt',
          visible: false,
        },
        {
          label: 'Date Cancelled',
          field: 'cancelledAt',
          visible: false,
        },
        {
          label: 'Registration Name',
          field: 'registrationName',
          visible: true,
        },
        {
          label: 'Name',
          field: 'name',
          visible: false,
        },
        {
          label: 'Address',
          field: 'address',
          visible: false,
        },
        {
          label: 'Email',
          field: 'email',
          visible: true,
        },
        {
          label: 'Number of Units',
          field: 'unitsQuantity',
          visible: false,
        },
        {
          label: 'Class & Kind',
          field: 'shareKind',
          visible: false,
        },
        {
          label: 'Acquired By',
          field: 'allotment',
          visible: false,
        },
        {
          label: 'Transfer From',
          field: 'transferredFrom',
          visible: false,
        },
        {
          label: 'Certificate Number',
          field: 'serial',
          visible: true,
        },
        {
          label: 'Agreed Per Unit',
          field: 'agreedPerUnit',
          visible: false,
        },
        {
          label: 'Paid Per Unit',
          field: 'cashPerUnit',
          visible: false,
        },
        {
          label: 'Paid (not cash)',
          field: 'otherPerUnit',
          visible: false,
        },
        {
          label: 'Details',
          field: 'cancellationDetails',
          visible: false,
        },
      ],
      selectAll: false,
      unselectAll: false,
      checkedRows: [],
      showInviteModal: false,
      inviteModal: {
        content: '',
        contentNotes: '',
        buttonText: 'INVITE',
      },
    };
  },
  computed: {
    tableData() {
      return this.certificates.map((certificate) => {
        const {issuedAt, cancelledAt, firstName, lastName, streetAddress, locality, region, country, postalCode} = certificate;
        return {
          ...certificate,
          issuedAt: Formatter.defaultTimestamp(issuedAt),
          cancelledAt: Formatter.defaultTimestamp(cancelledAt),
          name: `${firstName} ${lastName}`,
          address: streetAddress + (streetAddress ? ', ' : '') + locality + (locality ? ', ' : '') + region + (region ? ', ' : '') + country + (country ? ', ' : '') + postalCode,
        };
      });
    },
    showActionBar() {
      return this.checkedRows.length > 0;
    },
  },
  methods: {
    toggleSelectAll(ifChecked) {
      if (ifChecked) {
        this.checkboxList.map((each) => {
          each.visible = true;
        });
        this.unselectAll = false;
      }
    },

    toggleUnselectAll(ifChecked) {
      if (ifChecked) {
        this.checkboxList.map((each) => {
          each.visible = false;
        });
        this.selectAll = false;
      }
    },

    openInviteModal() {
      this.inviteModal.content = `You have selected ${this.checkedRows.length} ${this.checkedRows.length > 1 ? 'investors' : 'investor'} to be invited to ${this.legalEntityName}`;
      this.showInviteModal = true;
    },

    buttonAction() {
      // invite modal
      if (this.inviteModal.buttonText === 'INVITE') {
        this.validateInvite((text) => {
          this.inviteModal.content = 'Invitations were sent successfully!';
          this.inviteModal.contentNotes = text;
          this.inviteModal.buttonText = 'DONE';
        });
      } else { // result modal
        this.showInviteModal = false;
        this.resetModalContent();
      }
    },

    async validateInvite(callback) {
      const text = {
        success: '',
        errorDuplicatedEmail: 'We noticed duplicate emails in the selection and did not send additional invitations to each instance.',
        errorInviteSent: 'We noticed emails in the selection that have existing accounts with a previous invitation sent for this entity and did not send an additional invitation to those instances.',
      };

      let certificateIds = [];
      let emails = new Set();
      this.checkedRows.map(row => {
        certificateIds.push(row.id);
        emails.add(row.email);
      });
      try {
        const massInviteUrl = `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/csr/mass_invite`;
        const payload = {certificateIds};
        const result = await axios.post(massInviteUrl, payload);

        // Error state (duplicate email)
        if (emails.size < this.checkedRows.length) return callback(text['errorDuplicatedEmail']);
        const existingMembers = Number(result.data.status[1].split(':')[1]);
        const inviteSentButIgnored = Number(result.data.status[2].split(':')[1]);

        // Error state (existing account & invitation previous sent)
        if (existingMembers || inviteSentButIgnored) return callback(text['errorInviteSent']);

        // Ideal state
        callback(text['success']);
      } catch (error) {
        this.$buefy.notification.open({
          message: String(error),
          type: 'is-danger',
        });
      }
    },

    resetModalContent() {
      this.inviteModal.buttonText = 'INVITE';
      this.inviteModal.contentNotes = '';
    },
  },
};
</script>
