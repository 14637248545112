<template>
    <standard-modal @hide="modalShow = false" title="Manage Admin Privileges" class="modal" v-bind:class="{'is-active' : modalShow}">
        <p>Note: These values are hardcoded, and need to be switched over when the policies are defined</p>
        <b-field label="Select a Role">
            <b-select v-model="selectedRole" placeholder="Click to select...">
                <option v-for="role in roles" :value="role" :key="role">
                    {{ role }}
                </option>
            </b-select>
        </b-field>
        <div class="field is-grouped">
            <div class="control">
                <button class='button is-warning' @click.prevent="setAdminPrivileges()">
                    Ok
                </button>
            </div>
        </div>
    </standard-modal>
</template>

<script>
    import FailMessageModal from 'components/fail-message-modal/component.vue'
    import StandardModal from 'components/standard-modal/component.vue';
    import axios from 'utils/axios';

    export default {
        props: {
            memberData: Array
        },
        components: {StandardModal, FailMessageModal},
        data: () => {
            return {
                modalShow: false,
                memberId: null,
                memberEmail: null,
                selectedRole: null,
                roles: [
                    'None',
                    'Super Admin',
                    'Read Only',
                    'Accounts',
                    'Audit'
                ]
            }
        },
        methods: {
            show(email, role, id) {
                this.modalShow = true;
                this.memberEmail = email;
                this.memberId = id;
                if(this.selectedRole = false){
                    this.selectedRole = "None";
                } else {
                    this.selectedRole = role;
                }
            },
            setAdminPrivileges() {
                if(confirm(`You are about to grant ${this.memberEmail} admin privileges with the role of: ${this.selectedRole}.\n\n Are you sure you want to continue?`)){
                    return axios.put(`/admin/members/${this.memberId}/grant_privileges`,
                        {
                            admin_details: {
                                role: this.selectedRole,
                                email: this.memberEmail
                            },
                        }).then((result) => {
                        location.reload();
                    }).catch(this.genericFailure.bind(this));
                }
            },
            close() {
                this.modalShow = false;
            },
            genericFailure(xhr) {
                if(xhr.response && xhr.response.data && xhr.response.data.message) {
                    this.$refs.failModal.show(xhr.response.data.message);
                }  else {
                    this.$refs.failModal.show("An unknown error occurred and the developers have been notified.");
                    throw xhr;//might not actually be an xhr
                }
            }
        }
    };
</script>
