import Vue from 'vue/dist/vue.esm';
import addyData from 'utils/addy-data';
import PropertyForm from '../../../components/manager/properties/property_form';

const container = 'manager-entity-assets';
addyData.registerForLoadEvent('turbolinks:load', container);

document.addEventListener('addy-data:load', () => {
  if (document.getElementById(container)) {
    new Vue({
      el: `#${container}`,
      components: {PropertyForm},
      data: function() {
        return {property: addyData.fetchData(container)['property']};
      },
    });
  }
});
