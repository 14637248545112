<template>
  <form id="tenant-form">
    <div class="title">{{ title }}</div>
    <div class="subtitle">{{ subtitle }}</div>
    <div class="box">
      <p class="is-size-4">Contact Information</p>
      <b-field label="First Name">
        <b-input v-model="firstName" required/>
      </b-field>
      <b-field label="Middle Name">
        <b-input v-model="middleName"/>
      </b-field>
      <b-field label="Last Name">
        <b-input v-model="lastName" required/>
      </b-field>
      <b-field label="Email">
        <b-input v-model="email"/>
      </b-field>
      <b-field label="Phone number">
        <b-input v-model="phoneNumber"/>
      </b-field>
      <b-field label="Emergency Number">
        <b-input v-model="emergencyNumber"/>
      </b-field>
    </div>
    <div class="box">
      <p class="is-size-4">Tenancy Fields</p>
      <b-field label="Unit Number">
        <b-select
          placeholder="Select a unit"
          v-model="assetUnitId"
          @input.native="setAssetUnit">
          <option
             v-for="unit in units"
             :key="unit.id"
             :value="unit.id"
             :data-type="unit.data.unit.unitType">
             {{ unit.unitNumber }}
          </option>
        </b-select>
      </b-field>
      <residential-fields
        ref="residentialFields"
        v-if="unitType == 'residential'"
        :prefill-data="prefillData">
      </residential-fields>
      <commercial-fields
        ref="commercialFields"
        v-if="unitType == 'commercial'"
        :prefill-data="prefillData">
      </commercial-fields>
    </div>
    <br/>
    <tenancy-agreement-fields
      ref="tenancyAgreementFields"
      v-if="unitType"
      v-bind:unit-type="unitType"
      :prefill-data="prefillData">
    </tenancy-agreement-fields>
    <br/>
    <rent-and-deposit-fields
      ref="rentAndDepositFields"
      v-if="unitType"
      v-bind:unit-type="unitType"
      :prefill-data="prefillData">
    </rent-and-deposit-fields>
    <b-button tag="input"
              native-type="submit"
              @click.prevent="submit"
              value="Save" />
  </form>
</template>

<script>
import Axios from '../../../../utils/axios';
import ResidentialFields from './tenant_fields/residential_fields';
import CommercialFields from './tenant_fields/commercial_fields';
import FormData from 'form-data';
import TenancyAgreementFields from './tenant_fields/tenancy_agreement_fields';
import RentAndDepositFields from './tenant_fields/rent_and_deposit_fields';

export default {
  props: ['title', 'subtitle', 'accountId', 'legalEntityId', 'propertyId', 'tenantId'],
  components: {ResidentialFields, CommercialFields, TenancyAgreementFields, RentAndDepositFields},
  data() {
    return {
      // TODO: Refactor this into Contacts
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      emergencyNumber: '',
      units: null,
      assetUnitId: null,
      unitType: '',
      prefillData: null,
    };
  },
  created() {
    this.getTenant();
    this.getUnits();
  },
  computed: {
    indexPath: function() {
      return `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/properties/${this.propertyId}/tenants`;
    },
    showPath: function() {
      return `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/properties/${this.propertyId}/tenants/${this.tenantId}`;
    },
    createPath: function() {
      return `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/properties/${this.propertyId}/tenants`;
    },
    updatePath: function() {
      return `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/properties/${this.propertyId}/tenants/${this.tenantId}`;
    },
    unitsPath: function() {
      return `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/properties/${this.propertyId}/units`;
    },
  },
  methods: {
    getTenant: function() {
      if (this.tenantId) {
        const self = this;

        Axios.get(this.showPath, {
        }).then(function(response) {
          self.unitType = response.data.assetUnit.data.unit.unitType;
          self.assetUnitId = response.data.assetUnit.id;

          // Contact Attributes
          const {firstName, middleName, lastName, email, phone} = response.data.contact;
          self.firstName = firstName;
          self.middleName = middleName;
          self.lastName = lastName;
          self.email = email;
          self.phoneNumber = phone;
          self.emergencyNumber = response.data.meta.sharedFields.emergencyNumber;

          // TODO: split the data before we pass to components
          self.prefillData = response.data;
        }).catch(function(error) {
          // TODO: Drop silently?
          console.log('Silent Error ->', error);
        });
      }
    },
    getUnits: function() {
      const self = this;

      Axios.get(this.unitsPath, {
      }).then(function(response) {
        self.units = response.data;
      }).catch(function(error) {
        // TODO: Drop silently?
        console.log('Silent Error ->', error);
      });
    },
    setAssetUnit: function(event) {
      this.assetUnitId = event.target.value;
      this.unitType = event.target.selectedOptions[0].getAttribute('data-type');
    },
    unitTypeFields: function() {
      if (this.unitType == 'residential') {
        return this.$refs.residentialFields;
      } else if (this.unitType == 'commercial') {
        return this.$refs.commercialFields;
      }
      return {};
    },
    tenantPayload: function() {
      const data = new FormData();
      data.set('tenant[asset_unit_id]', this.assetUnitId);

      // Contact Attributes
      data.set('tenant[contact_attributes][first_name]', this.firstName);
      data.set('tenant[contact_attributes][middle_name]', this.middleName);
      data.set('tenant[contact_attributes][last_name]', this.lastName);
      data.set('tenant[contact_attributes][email]', this.email);
      data.set('tenant[contact_attributes][phone]', this.phoneNumber);

      // Shared Fields
      data.set('tenant[meta][shared_fields][emergency_number]', this.emergencyNumber);
      data.set(`tenant[meta][shared_fields][tenancy_agreement_start_date]`, this.$refs.tenancyAgreementFields.tenancyAgreementStartDate);
      data.set(`tenant[meta][shared_fields][tenancy_agreement_end_date]`, this.$refs.tenancyAgreementFields.tenancyAgreementEndDate);
      data.set(`tenant[meta][shared_fields][term_type]`, this.$refs.tenancyAgreementFields.termType);
      data.set('tenant[meta][shared_fields][security_deposit]', this.$refs.rentAndDepositFields.securityDeposit);

      // Residential Fields
      if (this.unitType == 'residential') {
        data.set('tenant[rental_start_date]', this.$refs.residentialFields.rentalStartDate);
        data.set('tenant[rental_end_date]', this.$refs.residentialFields.rentalEndDate);
        data.set('tenant[rental_amount]', this.$refs.rentAndDepositFields.rentalAmount);
        data.set('tenant[meta][residential_fields][end_of_term]', this.$refs.tenancyAgreementFields.endOfTerm);
        data.set('tenant[meta][residential_fields][pro_rated_rent_amount]', this.$refs.rentAndDepositFields.proRatedRentAmount);
        data.set('tenant[meta][residential_fields][pro_rated_rent_notes]', this.$refs.rentAndDepositFields.proRatedRentNotes);
        data.set('tenant[meta][residential_fields][pet_deposit]', this.$refs.rentAndDepositFields.petDeposit);
        data.set('tenant[meta][residential_fields][rent_payment_term]', this.$refs.rentAndDepositFields.rentPaymentTerm);

        // Encrypted Fields
        data.set('tenant[secure_meta][residential_fields][social_insurance_number]', this.$refs.residentialFields.socialInsuranceNumber);
        data.set('tenant[secure_meta][residential_fields][drivers_license_number]', this.$refs.residentialFields.driversLicenseNumber);
        // File Upload
        if (this.unitTypeFields().driversLicense) {
          data.append('tenant[files][drivers_license]', this.unitTypeFields().driversLicense);
        }
      }

      // Commercial Fields
      if (this.unitType == 'commercial') {
        data.set('tenant[rental_start_date]', this.$refs.rentAndDepositFields.rentalStartDate);
        data.set('tenant[rental_end_date]', this.$refs.rentAndDepositFields.rentalEndDate);
        data.set('tenant[rental_amount]', this.$refs.rentAndDepositFields.rentalAmount);
        data.set('tenant[meta][commercial_fields][company_name]', this.$refs.commercialFields.companyName);
        data.set('tenant[meta][commercial_fields][number_of_renewals]', this.$refs.tenancyAgreementFields.numberOfRenewals);
        data.set('tenant[meta][commercial_fields][length_of_renewal]', this.$refs.tenancyAgreementFields.lengthOfRenewal);
        data.set('tenant[meta][commercial_fields][rent_day]', this.$refs.rentAndDepositFields.rentDay);
        data.set('tenant[meta][commercial_fields][with_interest]', this.$refs.rentAndDepositFields.withInterest);
      }

      return data;
    },
    tenantHeaders() {
      return {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
    },
    submit: function(event) {
      const redirect = function() {
        window.location.replace(this.indexPath);
      }.bind(this);

      if (this.tenantId) {
        Axios.put(this.updatePath, this.tenantPayload(), this.tenantHeaders()).then(redirect);
      } else {
        Axios.post(this.createPath, this.tenantPayload(), this.tenantHeaders()).then(redirect);
      }
    },
  },
};
</script>
