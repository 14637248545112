<template>
  <div id="commercial-fields">
    <b-field label="Company Name">
      <b-input v-model="companyName"/>
    </b-field>
  </div>
</template>

<script>
export default {
  props: {
    prefillData: Object,
  },
  data() {
    return {
      companyName: null,
    };
  },
  created() {
    if (this.prefillData) {
      const {meta} = this.prefillData;
      this.companyName = meta.commercialFields.companyName;
    }
  },
};
</script>
