<template>
  <div class="box">
    <CsrTable :certificates="certificates" :legalEntityName="legalEntityName" :accountId="accountId" :legalEntityId="legalEntityId"></CsrTable>
    <div v-if="certificates.length === 0">
      <p>
        Click to
        <a :href="actionPath('new')"> add your first transaction</a>
        or
        <a :href="actionPath('upload')"> upload multiple transactions.</a>
      </p>
    </div>
  </div>
</template>

<script>
import Axios from 'utils/axios';
import Formatter from 'utils/date-time-formatter'
import CsrTable from './csr_table'

export default {
  components: {
    CsrTable
  },
  props: ['accountId', 'legalEntityId', 'legalEntityName'],
  data: function() {
    return {
      certificates: []
    };
  },
  created() {
    this.certificates = this.$parent.certificates;
  },
  computed: {
  },
  methods: {
    collectionPath() {
      return `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/csr`;
    },
    resourcePath(certificateId) {
      return `${this.collectionPath()}/${certificateId}`;
    },
    actionPath(action) {
      return `${this.collectionPath()}/${action}`;
    },
    editPath(certificateId) {
      return `${this.resourcePath(certificateId)}/edit`;
    },
    dateFormat(isoDate) {
      if (isoDate) return Formatter.defaultTimestamp(isoDate);
    },
    deleteCertificate(certificateId) {
      const redirect = function() {
        window.location.replace(this.collectionPath());
      }.bind(this);

      Axios.delete(this.resourcePath(certificateId)).then(redirect)
    },
  },
};
</script>
