<template>
  <form class="box">
    <p class="is-size-4">Address Information</p>
    <b-field label="Street Address" class="label">
      <b-input v-model="streetAddress"></b-input>
    </b-field>
    <b-field label="Extended Address" class="label">
      <b-input v-model="extendedAddress"></b-input>
    </b-field>
    <b-field label="City" class="label">
      <b-input v-model="locality"></b-input>
    </b-field>
    <b-field label="Province/State" class="label">
      <b-input v-model="region"></b-input>
    </b-field>
    <b-field label="Postal Code/Zip" class="label">
      <b-input v-model="postalCode"></b-input>
    </b-field>
    <b-field label="Country" class="label">
      <b-input v-model="country"></b-input>
    </b-field>
    <b-button tag="input"
              native-type="submit"
              @click.prevent="submit"
              value="Save" />
  </form>
</template>

<script>
import Axios from 'utils/axios';

export default {
  props: ['accountId', 'addressId', 'addressableId', 'addressableType'],
  data: function() {
    return {
      nickname: '',
      streetAddress: '',
      extendedAddress: '',
      locality: '',
      region: '',
      postalCode: '',
      country: '',
    };
  },
  created() {
    if (this.addressId) this.getAddress();
  },
  computed: {
    collectionPath() {
      return `/manager/accounts/${this.accountId}/addresses`;
    },
    resourcePath() {
      return `/manager/accounts/${this.accountId}/addresses/${this.addressId}`;
    },
  },
  methods: {
    getAddress() {
      const self = this;

      Axios.get(this.resourcePath).then(function(response) {
        const {nickname, streetAddress, extendedAddress, locality, region, postalCode, country} = response.data
        self.nickname = nickname;
        self.streetAddress = streetAddress;
        self.extendedAddress = extendedAddress;
        self.locality = locality;
        self.region = region;
        self.postalCode = postalCode;
        self.country = country;
      });
    },
    addressPayload() {
      const params = {
        address: {
          nickname: this.nickname,
          streetAddress: this.streetAddress,
          extendedAddress: this.extendedAddress,
          locality: this.locality,
          region: this.region,
          postalCode: this.postalCode,
          country: this.country,
        },
        addressable: {},
      };
      params['addressable'][`${this.addressableType}_id`] = this.addressableId;

      return params;
    },
    submit: function(event) {
      if (this.addressId) {
        Axios.put(this.resourcePath, this.addressPayload());
      } else {
        Axios.post(this.collectionPath, this.addressPayload());
      }
    },
  },
};
</script>
