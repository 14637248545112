import _ from 'lodash';
import axios from './axios';

const FormMixins = {
    methods: {
        classForField(field) {
            if (this.fields[field] && this.fields[field].valid) {
                return 'is-success';
            } else if (this.errors.has(field) && this.fields[field].touched) {
                return 'is-danger';
            } else {
                return '';
            }
        },
        firstErrorFor(field) {
            if (this.errors.has(field) && this.fields[field].touched) {
                return this.errors.first(field);
            } else {
                return '';
            }
        },
        failedXHR(xhr) {
            if (xhr.response.data && xhr.response.data.message) {
                this.$refs.failModal.show(xhr.response.data.message);
            } else {
                this.$refs.failModal.show("Sorry. An unknown error occurred.");
            }
        },
        updateInvestor(investorData) {
            return axios.put('/api/investors/me',
                {investor: investorData}
            ).catch(this.failedXHR.bind(this));
        },
        revalidate() {
            this.$validator.validateAll();
            this.$forceUpdate();
        },
        shortFileName(long_url) {
            try {
                return long_url.split("?")[0].split("/").pop();
            }
            catch(err) {
                return long_url.substring(long_url.lastIndexOf("/") + 1);
            }
        }
    },
    computed: {
        formValidated() {
            return this.errors.items.length == 0 &&
                _.every(Object.keys(this.fields), (key => this.fields[key].validated)) &&
                _.every(Object.keys(this.fields), (key => this.fields[key].valid));
        },
    }
};

export default FormMixins;
