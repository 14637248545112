import Vue from 'vue/dist/vue.esm';
import AddressBuilder from 'components/addresses/address_builder.vue';
import addyData from 'utils/addy-data';

const container = 'asset-unit-form-container';

addyData.registerForLoadEvent('turbolinks:load', container);

document.addEventListener('addy-data:load', () => {
  if (document.getElementById(container)) {
    const assetUnitData = addyData.fetchData(container)['assetUnit'];

    new Vue({
      el: `#${container}`,
      components: {AddressBuilder},
      data: Object.assign({}, assetUnitData),
    });
  }
});
