<template>
    <div class="columns is-fullheight">
        <div class="column is-three-fifths is-offset-one-fifth">
            <member-search v-bind:member-data="memberData"/>
        </div>
    </div>
</template>

<script>
    import MemberSearch from './member_search';

    export default {
        props:{
            memberData: Array
        },
        components: {MemberSearch}
    };
</script>
