import {DateTime} from 'luxon';

const DateTimeFormatter = {
  defaultTimestamp(isoDate) {
    return isoDate ? DateTime.fromISO(isoDate).toLocaleString(DateTime.DATE_FULL) : '';
  },
  // -- Deprecated -------
  methods: {
    dateFormatter(date) {
      const year = `${date.getFullYear()}`.padStart(4, '0');
      const month = `${date.getMonth() + 1}`.padStart(2, '0');
      const stringDate = `${date.getDate()}`.padStart(2, '0');

      return `${year}-${month}-${stringDate}`;
    },
    timeFormatter(date) {
      const hours = `${date.getHours()}`.padStart(2, '0');
      const minutes = `${date.getMinutes()}`.padStart(2, '0');

      return `${hours}:${minutes}`;
    }
  }
};

export default DateTimeFormatter;
