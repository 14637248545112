import Vue from 'vue/dist/vue.esm';
import addyData from 'utils/addy-data';
import InsuranceForm from '../../../components/manager/insurance/insurance_form';

const container = 'manager-insurance-show';
addyData.registerForLoadEvent('turbolinks:load', container);

document.addEventListener('addy-data:load', () => {
  if (document.getElementById(container)) {
    new Vue({
      el: `#${container}`,
      components: {InsuranceForm},
      data: function() {
        return {insurance: addyData.fetchData(container)['insurance']};
      },
    });
  }
});
