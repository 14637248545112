<template>
  <form>
    <div class="title">{{ title }}</div>
    <div class="subtitle">{{ subtitle }}</div>
    <property-information
      :account-id="accountId"
      :legal-entity-id="legalEntityId"
      :property-id="propertyId"
      :property="property">
    </property-information>
    <hr>
    <address-builder
      v-if="propertyId"
      :account-id="accountId"
      :address-id="property.addressId"
      :addressableId="property.id"
      addressableType="entity_asset">
    </address-builder>
  </form>
</template>

<script>
import PropertyInformation from '../../../components/manager/properties/property_information';
import AddressBuilder from '../../../components/addresses/address_builder';

export default {
  props: ['title', 'subtitle', 'accountId', 'legalEntityId', 'propertyId'],
  components: {PropertyInformation, AddressBuilder},
  data() {
    return {
      property: null,
    };
  },
  created() {
    this.property = this.$parent.property;
  },
};
</script>
