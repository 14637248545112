import Vue from 'vue/dist/vue.esm';
import addyData from 'utils/addy-data';
import CsrForm from '../../../components/manager/csr/csr_form';

const container = 'manager-csr-new';
addyData.registerForLoadEvent('turbolinks:load', container);

document.addEventListener('addy-data:load', () => {
  if (document.getElementById(container)) {
    new Vue({
      el: `#${container}`,
      components: {CsrForm},
      data: function() {
        return {};
      },
    });
  }
});
