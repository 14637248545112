document.addEventListener('turbolinks:load', () => {
  const container = document.getElementById('manager_account_edit');

  if(container) {
    const fileInput = document.getElementById('account_logo');

    fileInput.addEventListener('change', () => {
      const file = fileInput.files[0];
      const reader = new FileReader();
      const logo = document.getElementById('logo');

      reader.addEventListener('load', () => {
        logo.src = reader.result;
      }, false);

      if(file) {
        reader.readAsDataURL(file);
      }
    });
  }
});
