// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("buefy")
require("@fortawesome/fontawesome-free/js/all")

import Vue from 'vue/dist/vue.esm';
import Buefy from 'buefy'

import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'

// TODO: Detect envs and set dev mode for CLI usage
Vue.config.devtools = true
Vue.component('ValidationProvider', ValidationProvider)
Vue.use(Buefy, {
  defaultIconPack: 'fa'
})

require("components/admin-and-manager-mobile-nav")

require("components/manager/bank-accounts/bank-account-form")
require("components/manager/legal-entities/entity-form")
require("components/manager/properties/updates")
require("components/manager/properties/unit-form")

require("components/admin/accounts/accounts-form")
require("components/admin/members/members-index")
require("components/admin/members/members-show")
require("components/admin/members/members-edit")

require("components/login")
require("components/member/signup")
require("components/addyplus/signup")

// Reusable components
require("components/fileupload")
require('components/contacts/contact_builder')
require('components/addresses/address_builder')
require('components/manager/activity_entries/activity_entries_listing')
require('components/manager/activity_entries/activity_entry_form')
require('components/manager/csr/csr_form')
require('components/manager/csr/csr_listing')
require('components/manager/properties/tenants/tenant_form')
require('components/manager/properties/property_form')
require('components/manager/properties/property_information')
require('components/manager/insurance/insurance_listing')
require('components/manager/insurance/insurance_form')

require('views/initializers/contacts/contact_builder')

require('views/manager/account/edit')
require('views/manager/activity_entries/index')
require('views/manager/activity_entries/edit')
require('views/manager/activity_entries/new')
require('views/manager/csr/index')
require('views/manager/csr/new')
require('views/manager/csr/edit')
require('views/manager/csr/upload')
require('views/manager/entity_assets/form')
require('views/manager/properties/tenants/form')
require('views/manager/insurance/index')
require('views/manager/insurance/show')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
