import axios from 'utils/axios';

const addyData = {
  registerForLoadEvent: (event, container) => {
    if (typeof document.addyDataStore == 'undefined') {
      document.addyDataStore = {_endpoints: {}};
    }

    document.addEventListener(event, () => {
      if (document.getElementById(container)) {
        addyData.enqueueURLEndpoint(container, document.location.href);
        addyData.fetchRemoteData();
      }
    });
  },
  enqueueURLEndpoint: (dest, url) => {
    if (!document.addyDataStore._endpoints.hasOwnProperty(dest)) {
      document.addyDataStore._endpoints[dest] = url;
    }
  },
  fetchData: (dest) => {
    if (typeof dest == 'undefined') {
      return {};
    }

    return document.addyDataStore[dest];
  },
  fetchRemoteData: () => {
    const requests = Object.entries(document.addyDataStore._endpoints).map((opts) => axios.get(opts[1]));

    Promise.all(requests).then((results) => {
      const endpoints = document.addyDataStore._endpoints;

      for (const response of results) {
        const url = response.config.url;
        const dest = Object.keys(endpoints).find((key) => endpoints[key] == url);

        document.addyDataStore[dest] = response.data;
      }

      document.addyDataStore._endpoints = {};

      document.dispatchEvent(new Event('addy-data:load'));
    });
  },
};

export default addyData;
