<template>
  <div class="is-flex flex-between csr-page-top">
    <div>
      <div class="title">Corporate Securities Register</div>
      <div class="subtitle">{{ legalEntityName }}</div>
    </div>
    <div class="select">
      <select @change="startAction">
        <option value disabled selected>Actions...</option>
        <optgroup label="Add Transaction">
          <option value="single_purchase">Add Single Purchase</option>
          <option value="single_transfer">Add Single Transfer</option>
          <option value="single_conversion">Add Single Conversion</option>
        </optgroup>
        <optgroup label="Export">
          <option value="upload_multiple">Upload Multiple Transactions</option>
          <option value="export_csv">Export as CSV</option>
        </optgroup>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  props: ['legalEntityName'],
  methods: {
    startAction: function (event) {
      switch (event.target.value) {
        case "single_purchase":
          window.location.assign(`${window.location.href}/new`);
          break;
        case "upload_multiple":
          window.location.assign(`${window.location.href}/upload`);
          break;
        case "export_csv":
          window.location.assign(`${window.location.href}/export?format=csv`);
          break;
      }
    },
  },
};
</script>
