import Vue from 'vue/dist/vue.esm';
import ConvertToAdminModal from 'components/admin/members/members-show/convert_to_admin_modal.vue';
import addyData from 'utils/addy-data';

const container = 'member-show-container';

addyData.registerForLoadEvent('turbolinks:load', container);

document.addEventListener('addy-data:load', () => {
  if (document.getElementById(container)) {
    const memberData = addyData.fetchData(container)['member'];
    const adminData = addyData.fetchData(container)['administrator'];

    new Vue({
      el: `#${container}`,
      components: {ConvertToAdminModal},
      data: Object.assign({
        adminRole: null,
      }, memberData),
      created() {
        this.setupAdminRole();
      },
      methods: {
        showConvertMemberModal() {
          console.log(memberData.email, this.adminRole);
          this.$refs.convertMemberModal.show(memberData.email, this.adminRole, memberData.id);
        },
        setupAdminRole() {
          if (adminData.role) {
            this.adminRole = adminData.role;
          } else {
            this.adminRole = 'None';
          }
        },
      },
    });
  }
});
