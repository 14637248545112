import Vue from 'vue/dist/vue.esm';
import MemberList from 'components/admin/members/members-index/component.vue';
import addyData from 'utils/addy-data';

const container = 'member-list-container';

addyData.registerForLoadEvent('turbolinks:load', container);

document.addEventListener('addy-data:load', () => {
  if (document.getElementById(container)) {
    const memberData = addyData.fetchData(container)['members'];

    new Vue({
      el: `#${container}`,
      components: {MemberList},
      data() {
        return {memberData};
      },
    });
  }
});
