<template>
    <div>
        <div class="title has-text-centered">
          Create an addy Plus Account
        </div>
        <div class="field">
            <b>Organizaton Name</b>
            <b-tooltip label="This how you refer to your organization"
                       type="is-info" animated>
              <i class="fa fa-info-circle fa-sm" aria-hidden="true"></i>
            </b-tooltip>
            <b-input v-model="displayName" name="display_name"/>
        </div>
        <div class="field">
            <b>Email</b>
            <b-tooltip label="Use your real email as you'll need to validate it to setup your account."
                       type="is-info" animated>
              <i class="fa fa-info-circle fa-sm" aria-hidden="true"></i>
            </b-tooltip>
            <b-input v-model="member.email" name="email"/>
        </div>
        <b-field label="Password">
          <b-input type="password"
                   password-reveal
                   v-model="member.password"
                   name="password"/>
        </b-field>
        <div class="field">
          <b-checkbox v-model="member.accepted_terms_at" name="accepted_terms_at"> Accept
            <a href="https://addyinvest.com/terms-of-service/"> Terms of Service</a>
          </b-checkbox>
        </div>
        <div class="actions">
          <promise-loading-button :action="createAccount"
                                  class="button is-primary is-fullwidth">
            Create Account
          </promise-loading-button>

          <fail-message-modal ref="failModal" title="Oh no!"/>
        </div>
    </div>
</template>

<script>
    import PromiseLoadingButton from 'components/promise-loading-button/component.vue'
    import FailMessageModal from 'components/fail-message-modal/component.vue'
    import axios from 'utils/axios';
    import FormMixins from 'utils/form-mixins';

    export default {
        props: [],
        components: {FailMessageModal, PromiseLoadingButton},
        data: () => {
          return {member: {}, displayName: null}
        },
        mixins: [FormMixins],
        methods: {
          createAccount() {
            return axios.post('/api/accounts',
              {
                account: {
                  display_name: this.displayName,
                  member: this.member
                }
              }).then(this.accountCreated.bind(this))
              .catch(this.failedXHR.bind(this));
          },
          accountCreated(xhr) {
            window.location.href = '/signup/unverified';
          }
        }
    };
</script>
