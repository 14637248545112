import Vue from 'vue/dist/vue.esm';
import CanadianBankNames from 'data/canadian_bank_names';
import _ from 'lodash';
import addyData from 'utils/addy-data';

const container = 'bank-account-form-container';

addyData.registerForLoadEvent('turbolinks:load', container);

document.addEventListener('addy-data:load', () => {
  if (document.getElementById(container)) {
    const accountData = addyData.fetchData(container)['bankAccount'];
    const legalEntityData = addyData.fetchData(container)['legalEntity'];

    new Vue({
      el: `#${container}`,
      components: {},
      data: Object.assign({
        CanadianBankNames: CanadianBankNames,
        selected: null,
        bankName: '',
        accountNumber: null,
        accountManager: null,
        accountType: null,
        currency: null,
        institutionNumber: null,
        intrust: null,
        nickname: null,
        transitNumber: null,
        isIntrust: null,
        signatories: [],
        newSignatory: '',
        accountTypes: [
          {
            value: 'chq',
            type: 'Chequing',
          },
          {
            value: 'sav',
            type: 'Saving',
          },
        ],
        currencyTypes: [
          {
            value: 'cad',
            type: 'CAD',
          },
          {
            value: 'usd',
            type: 'USD',
          },
        ],
      }, accountData, legalEntityData),
      created() {
        !this.intrust ? this.isIntrust = false : this.isIntrust = this.intrust;
        if (!this.signatories) {
          this.signatories = [];
        }
      },
      methods: {
        removeSignatory(index) {
          this.signatories.splice(index, 1);
        },
        addSignatory() {
          if (this.newSignatory.trim()) {
            this.signatories.push(this.newSignatory);
            this.newSignatory = '';
          }
        },
      },
      computed: {
        filteredCanadianBankNames() {
          return _.filter(CanadianBankNames, (option) => {
            return option.toString().toLowerCase().indexOf(this.bankName) >= 0;
          });
        },
      },
      watch: {
        isIntrust: function(value) {
          this.intrust = value;
        },
      },
    });
  }
});
