import Vue from 'vue/dist/vue.esm';
import ContactBuilder from '../../../components/contacts/contact_builder'

document.addEventListener('turbolinks:load', () => {
  const nodes = document.getElementsByClassName('contact-builder');

  for(let node of nodes) {
    new Vue({
      el: node,
      components: {ContactBuilder}
    });
  }
});
