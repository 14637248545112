import Vue from 'vue/dist/vue.esm';
import addyData from 'utils/addy-data';
import ActivityEntriesListing from '../../../components/manager/activity_entries/activity_entries_listing';

const container = 'manager-activity-entries-index';
addyData.registerForLoadEvent('turbolinks:load', container);

document.addEventListener('addy-data:load', () => {
  if (document.getElementById(container)) {
    new Vue({
      el: `#${container}`,
      components: {ActivityEntriesListing},
      data: function() {
        return {activityEntries: addyData.fetchData(container)['activityEntries']};
      },
    });
  }
});
