import applyCaseMiddleware from 'axios-case-converter';
import axios from 'axios';

const token = document.getElementsByName('csrf-token')[0].getAttribute('content');
const instance = applyCaseMiddleware(axios.create());

instance.defaults.headers.common['X-CSRF-Token'] = token;
instance.defaults.headers.common['Accept'] = 'application/json';
instance.defaults.timeout = 3000;

export default instance;
