<template>
  <div class="box">
    <p class="is-size-4">Tenancy Agreement</p>
    <div class="columns" id="residential-agreement" v-if="unitType == 'residential'">
      <div class="column is-one-quarter">
        <b-field label="Beginning of Agreement">
          <b-datepicker v-model="tenancyAgreementStartDate" placeholder="Click to select..."></b-datepicker>
        </b-field>
      </div>
      <div class="column is-one-quarter">
        <b-field label="Term">
          <b-select placeholder="Select a Term" v-model="termType">
            <option value="" disabled>Term</option>
            <option value="fixed_term">Fixed Term</option>
            <option value="month_to_month">Month to Month</option>
            <option value="weekly">Weekly</option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-one-quarter">
        <b-field label="End Of Agreement">
          <b-datepicker v-model="tenancyAgreementEndDate" placeholder="Click to select..."></b-datepicker>
        </b-field>
      </div>
      <div class="column is-one-quarter">
        <b-field label="End of Term">
          <b-select placeholder="Select Term End" v-model="endOfTerm">
            <option value="" disabled>Term End</option>
            <option value="month_to_month">Month to Month</option>
            <option value="tenancy_ended">Tenancy Ended</option>
          </b-select>
        </b-field>
      </div>
    </div>

    <div class="columns" id="commercial-agreement" v-if="unitType == 'commercial'">
      <div class="column is-one-quarter">
        <b-field label="Beginning of Agreement">
          <b-datepicker v-model="tenancyAgreementStartDate" placeholder="Click to select..."></b-datepicker>
        </b-field>
        <b-field label="Term">
          <b-select placeholder="Select a Term" v-model="termType">
            <option value="" disabled>Term</option>
            <option value="fixed_term">Fixed Term</option>
            <option value="month_to_month">Month to Month</option>
            <option value="weekly">Weekly</option>
          </b-select>
        </b-field>
        <b-field label="End Of Agreement">
          <b-datepicker v-model="tenancyAgreementEndDate" placeholder="Click to select..."></b-datepicker>
        </b-field>
      </div>
      <div class="column is-one-quarter">
        <b-field label="Number of Renewal Terms">
          <b-input v-model="numberOfRenewals" placeholder="2" type="number"/>
        </b-field>
        <b-field label="Length of Renewal Term">
          <b-input v-model="lengthOfRenewal" placeholder="5 (Years)" type="number"/>
        </b-field>
      </div>
      <div class="column is-half">
        <b-field label="Summary">
          <div class="box">
            <p>
              The term of this lease is for <strong>{{this.lengthOfRenewal || '[x]'}}</strong> years commencing on <strong>{{agreementStartDateSummary}}</strong>
              and ending on <strong>{{agreementEndDateSummary}}</strong>.
            </p>
            <p>
              Tenant has been granted a renewal lease up to <strong>{{this.numberOfRenewals || '[x]'}}</strong> further terms of <strong>{{this.lengthOfRenewal || '[x]'}}</strong>
              years, ending on <strong>{{dateOfRenewalTermCompletionSummary}}</strong>.
            </p>
          </div>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    unitType: String,
    prefillData: Object,
  },
  data() {
    return {
      tenancyAgreementStartDate: null,
      tenancyAgreementEndDate: null,
      termType: null,
      endOfTerm: null,
      numberOfRenewals: null,
      lengthOfRenewal: null,
    };
  },
  created() {
    if (this.prefillData) {
      const meta = this.prefillData.meta;
      // Shared Fields
      this.tenancyAgreementStartDate = new Date(meta.sharedFields.tenancyAgreementStartDate);
      this.tenancyAgreementEndDate = new Date(meta.sharedFields.tenancyAgreementEndDate);
      this.termType = meta.sharedFields.termType;
      // Residential Fields
      if (this.unitType == 'residential') {
        this.termType = meta.sharedFields.termType;
        this.endOfTerm = meta.residentialFields.endOfTerm;
      }
      // Commercial Fields
      if (this.unitType == 'commercial') {
        this.numberOfRenewals = meta.commercialFields.numberOfRenewals;
        this.lengthOfRenewal = meta.commercialFields.lengthOfRenewal;
      }
    }
  },
  computed: {
    agreementStartDateSummary: function() {
      if (this.tenancyAgreementStartDate) {
        return this.tenancyAgreementStartDate.toDateString();
      } else {
        return '[date]';
      }
    },
    agreementEndDateSummary: function() {
      if (this.tenancyAgreementEndDate) {
        return this.tenancyAgreementEndDate.toDateString();
      } else {
        return '[date]';
      }
    },
    dateOfRenewalTermCompletionSummary() {
      if (!this.tenancyAgreementEndDate) {
        return '[date]';
      }
      const date = this.tenancyAgreementEndDate;
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      const summarizedDate = new Date(year + (this.lengthOfRenewal * this.numberOfRenewals), month, day);
      return summarizedDate.toDateString();
    },
  },
};
</script>
