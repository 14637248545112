import Vue from 'vue/dist/vue.esm';
import addyData from 'utils/addy-data';
import ActivityEntryForm from '../../../components/manager/activity_entries/activity_entry_form';

const container = 'manager-activity-entries-edit';
addyData.registerForLoadEvent('turbolinks:load', container);

document.addEventListener('addy-data:load', () => {
  if (document.getElementById(container)) {
    new Vue({
      el: `#${container}`,
      components: {ActivityEntryForm},
      data: function() {
        return {activityEntry: addyData.fetchData(container)['activityEntry']};
      },
    });
  }
});
