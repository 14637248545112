import Vue from 'vue/dist/vue.esm'
import CreateMember from 'components/member/signup/app.vue'

document.addEventListener('turbolinks:load', () => {
    const container = 'create-member-container';
    if (document.getElementById(container)){
        const signup = new Vue({
            el: `#${container}`,
            components: {CreateMember}
        })
    }
})
