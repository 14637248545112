<template>
    <div id="" class="is-fullheight">
        <div class="section is-fullheight">
            <div class="columns is-fullheight">
                <div class="column is-3 is-offset-5">
                    <create-addy-account/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FailMessageModal from 'components/fail-message-modal/component.vue'
    import CreateAddyAccount from "./create-addy-account";

    export default {
        components: {CreateAddyAccount, FailMessageModal},
    };
</script>
