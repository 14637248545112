import Vue from 'vue/dist/vue.esm'
import Fileupload from 'components/fileupload/fileupload.vue'

document.addEventListener('turbolinks:load', () => {
  const documentsIndex = document.getElementById('documents-index');

  if(documentsIndex) {
    new Vue({
      el: documentsIndex,
      components: {Fileupload}
    });
  }
});
