import Vue from 'vue/dist/vue.esm'
import Login from 'components/login/app.vue'
import VueSession from 'vue-session';
import VueRouter from 'vue-router';

// TODO: This is live EVERYWHERE
// This needs to be scoped down to login only
document.addEventListener('turbolinks:load', () => {
  Vue.use(VueSession, {persist: true});
  Vue.use(VueRouter);

  const router = new VueRouter({
    mode: 'history',
    routes: []
  });

  const container = 'login-container';
  if(document.getElementById(container)){
    const login = new Vue({
      el: `#${container}`,
      components: { Login },
      router: router,
      created() {
        this.$session.start();
      }
    })
  }
})
