<template>
  <form class="box">
    <div class="title">{{ action }} Update Entry</div>
    <div class="subtitle">{{ subtitle }}</div>
    <b-field class="label" label="Update Text">
      <textarea
        class="textarea"
        placeholder="Add a description..."
        v-model="description">
      </textarea>
    </b-field>
    <b-field label="Update Type">
      <b-select
        type="input"
        v-model="activity">
        <option value="null" disabled>Select Type</option>
        <option value="bill">Bill</option>
        <option value="community">Community</option>
        <option value="event">Event</option>
        <option value="insurance">Insurance</option>
        <option value="maintenance">Maintenance</option>
        <option value="rent_bounced">Rent Bounced</option>
        <option value="rent_paid">Rent Paid</option>
        <option value="taxes">Taxes</option>
        <option value="vacancy">Vacancy</option>
        <option value="disbursement">Disbursement</option>
        <option value="partial_rent_paid">Partial Rent Paid</option>
      </b-select>
    </b-field>
    <b-field label="Public?">
      <b-switch v-model="isPublic">
        {{ publicLabel }}
      </b-switch>
    </b-field>
    <b-field label="Update Date">
      <b-datepicker
        placeholder="Click to select..."
        v-model="entryAt">
        <button
          class="button is-primary"
          @click.prevent="entryAt = new Date()">
          <i class="fas fa-calendar"></i>
          <span>&nbsp;Today</span>
        </button>
        <button
          class="button is-danger"
          @click.prevent="entryAt = null">
          <i class="fas fa-times"></i>
          <span>&nbsp;Clear</span>
        </button>
      </b-datepicker>
    </b-field>
    <b-field label="Properties">
      <b-select
        multiple
        v-model="propertyIds">
        <option v-for="property in properties" :value="property.id" :key="property.id">
          {{ property.name }}
        </option>
      </b-select>
    </b-field>
    <b-field class="file">
      <b-upload v-model='attachment'>
        <a class="button is-dark upload-button">
          <b-icon icon='upload' pack='fas'></b-icon>
          <span>Choose File...</span>
        </a>
      </b-upload>
      <span class="file-name" style="width:250px;">
        <template v-if="attachment">
          {{ attachment.name }}
        </template>
      </span>
    </b-field>

    <div class="field is-grouped is-grouped-right">
      <div class="control">
        <button class="button is-rounded is-primary" type="submit" @click.prevent="submit">Save</button>
      </div>
      <div class="control">
        <button class="button is-link is-light">Cancel</button>
      </div>
    </div>
  </form>
</template>

<script>
import Axios from '../../../utils/axios';

export default {
  props: ['accountId', 'legalEntityId', 'activityEntryId', 'subtitle'],
  data: function() {
    return {
      description: '',
      activity: '',
      isPublic: false,
      entryAt: null,
      attachment: null,
      properties: [],
      propertyIds: [],
    };
  },
  created() {
    if (this.activityEntryId) {
      const {description, activity, isPublic, entryAt, properties, propertyIds} = this.$parent.activityEntry;

      this.description = description;
      this.activity = activity;
      this.isPublic = isPublic;
      this.entryAt = new Date(entryAt);

      this.properties = properties;
      this.propertyIds = propertyIds;
    }
  },
  computed: {
    action() {
      return this.activityEntryId ? 'Edit' : 'New';
    },
    publicLabel() {
      return this.isPublic == true ? 'Yes' : 'No';
    },
    collectionPath() {
      return `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/activity_entries`;
    },
    resourcePath() {
      return `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/activity_entries/${this.activityEntryId}`;
    },
  },
  methods: {
    activityEntryPayload() {
      const formData = new FormData();
      formData.append('activityEntry[description]', this.description);
      formData.append('activityEntry[activity]', this.activity);
      formData.append('activityEntry[public]', this.isPublic);
      formData.append('activityEntry[entryAt]', this.entryAt);
      this.propertyIds.forEach(function(propertyId) {
        formData.append('activityEntry[propertyIds][]', propertyId);
      });

      if (this.attachment) formData.append('activityEntry[attachment]', this.attachment);

      return formData;
    },
    activityEntryHeaders() {
      return {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
    },
    submit() {
      const redirect = function() {
        window.location.replace(this.collectionPath);
      }.bind(this);

      if (this.activityEntryId) {
        Axios.put(this.resourcePath, this.activityEntryPayload(), this.activityEntryHeaders).then(redirect);
      } else {
        Axios.post(this.collectionPath, this.activityEntryPayload(), this.activityEntryHeaders).then(redirect);
      }
    },
  },
};
</script>
