<template>
  <form>
    <div class="title">{{ title }}</div>
    <div class="subtitle">{{ subtitle }}</div>
    <b-field label="Display Name">
      <b-input v-model="displayName"></b-input>
    </b-field>
    <b-field label="First Name">
      <b-input v-model="firstName"></b-input>
    </b-field>
    <b-field label="Last Name">
      <b-input v-model="lastName"></b-input>
    </b-field>
    <b-field label="Phone">
      <b-input v-model="phone"></b-input>
    </b-field>
    <b-field label="Email">
      <b-input v-model="email"></b-input>
    </b-field>
    <b-field grouped position="is-right">
      <div class="control">
        <b-button type="is-primary" @click="submit">Submit</b-button>
      </div>
      <div class="control">
        <b-button type="is-light" @click="cancel">Cancel</b-button>
      </div>
    </b-field>
  </form>
</template>

<script>
import axios from '../../utils/axios';

export default {
  props: ['title', 'subtitle', 'accountid', 'contactid'],
  created: function() {
    if (this.contactid) {
      const self = this;
      axios.get(`/manager/accounts/${this.accountid}/contacts/${this.contactid}`)
          .then(function(response) {
            self.displayName = response.data.displayName;
            self.firstName = response.data.firstName;
            self.lastName = response.data.lastName;
            self.phone = response.data.phone;
            self.email = response.data.email;
          });
    }
  },
  data: function() {
    return {
      displayName: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
    };
  },
  methods: {
    submit: function() {
      axios.post(`/manager/accounts/${this.accountid}/contacts`, {
        contact: {
          displayName: this.displayName,
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          email: this.email,
        },
      })
          .then(function(response) {
            window.location.href = response.config.url;
          })
          .catch(function(error) {
          // TODO: Handle errors here
          });
    },
    cancel: function() {
      history.back();
    },
  },
};
</script>
