<template>
  <div class="file">
    <label class="file-label">
      <input class="file-input" type="file" :name="attach" @change="fileSelected">
      <span class="file-cta">
        <i class="fas fa-upload"></i>
        <span class="file-label">Upload</span>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: ['attach'],
  methods: {
    fileSelected: function(event) {
      event.target.form.submit();
    }
  }
};
</script>
