<template>
  <div class="box">
    <p class="is-size-4">Rent & Deposits</p>
    <!-- RESIDENTIAL   -->
    <div class="columns is-multiline" id="residential-rent" v-if="unitType == 'residential'">
      <!--  First Row  -->
      <div class="column is-one-quarter">
        <b-field label="Pro Rated Rent">
          <b-input v-model="proRatedRentAmount" placeholder="$1,450.00" type="number" min="0" step=".01"/>
        </b-field>
      </div>
      <div class="column is-half">
        <b-field label="Pro Rated Rent Notes">
          <b-input v-model="proRatedRentNotes" placeholder="10th - 31st rent for May 2019" type="textarea" rows="1"/>
        </b-field>
      </div>
      <!-- padding  -->
      <div class="column is-one-quarter"></div>
      <!--  END    -->

      <!--  Second Row  -->
      <div class="column is-one-quarter">
        <b-field label="Rent Amount">
          <b-input v-model="rentalAmount" placeholder="$2,500" type="number" min="0" step=".01" required/>
        </b-field>
      </div>
      <div class="column is-one-quarter">
        <b-field label="Rent Paid">
          <b-select placeholder="Monthly" v-model="rentPaymentTerm">
            <option value="" disabled>Rent Paid</option>
            <option value="month_to_month">Monthly</option>
            <option value="weekly">Weekly</option>
            <option value="daily">Daily</option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-one-quarter">
        <b-field label="Security Deposit">
          <b-input v-model="securityDeposit" placeholder="$2,500" type="number" min="0" step=".01"/>
        </b-field>
      </div>
      <div class="column is-one-quarter">
        <b-field label="Pet Deposit">
          <b-input v-model="petDeposit" placeholder="$500" type="number" min="0" step=".01"/>
        </b-field>
      </div>
      <!--  END    -->
    </div>

    <!-- COMMERCIAL   -->
    <div class="columns is-multiline" id="commercial-rent" v-if="unitType == 'commercial'">
      <div class="column is-one-quarter">
        <b-field label="Rent Amount">
          <b-input v-model="rentalAmount" placeholder="$2,500" type="number" min="0" step=".01"/>
        </b-field>
        <b-field label="Day of the Month">
          <b-input v-model="rentDay" placeholder="11" type="number"/>
        </b-field>
      </div>

      <div class="column is-one-quarter">
        <b-field label="Rental Start Date">
          <b-datepicker v-model="rentalStartDate" placeholder="Click to select..." required></b-datepicker>
        </b-field>
        <b-field label="Rental End Date">
          <b-datepicker v-model="rentalEndDate" placeholder="Click to select..." required></b-datepicker>
        </b-field>
      </div>

      <div class="column is-half">
        <b-field label="Summary">
          <div class="box">
            <p>
              The sum of <strong>{{rentalAmountSummary}}</strong> on
              <strong>{{rentalStartDateSummary}}</strong> and on the <strong>{{rentDaySummary}}</strong>
              day of each month therafter until and including <strong>{{rentalEndDateSummary}}</strong>.
            </p>
          </div>
        </b-field>
      </div>

      <div class="column is-one-quarter">
        <b-field label="Security Deposit">
          <b-input v-model="securityDeposit" placeholder="$2,500" type="number" min="0" step=".01"/>
        </b-field>
        <b-field label="Held">
          <b-switch v-model="withInterest">
            {{ heldWithInterest}}
          </b-switch>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    unitType: String,
    prefillData: Object,
  },
  data() {
    return {
      rentPaymentTerm: null,
      rentalAmount: null,
      withInterest: false,
      rentalStartDate: null,
      rentalEndDate: null,
      rentDay: null,
      securityDeposit: null,
      petDeposit: null,
      proRatedRentAmount: null,
      proRatedRentNotes: null,
    };
  },
  created() {
    if (this.prefillData) {
      const {rentalAmount, rentalStartDate, rentalEndDate, meta} = this.prefillData;

      // Residential Fields
      if (this.unitType == 'residential') {
        this.rentalAmount = rentalAmount;
        this.securityDeposit = meta.sharedFields.securityDeposit;
        this.proRatedRentAmount = meta.residentialFields.proRatedRentAmount;
        this.proRatedRentNotes = meta.residentialFields.proRatedRentNotes;
        this.petDeposit = meta.residentialFields.petDeposit;
        this.rentPaymentTerm = meta.residentialFields.rentPaymentTerm;
      }

      // Commercial Fields
      if (this.unitType == 'commercial') {
        this.rentalStartDate = new Date(rentalStartDate);
        this.rentalEndDate = new Date(rentalEndDate);
        this.rentalAmount = rentalAmount;
        this.securityDeposit = meta.sharedFields.securityDeposit;
        this.rentDay = meta.commercialFields.rentDay;
        this.withInterest = meta.commercialFields.withInterest;
      }
    }
  },
  methods: {
    ordinalSuffixOf(i) {
      const j = i % 10;
      const k = i % 100;
      if (j == 1 && k != 11) {
        return i + 'st';
      }
      if (j == 2 && k != 12) {
        return i + 'nd';
      }
      if (j == 3 && k != 13) {
        return i + 'rd';
      }
      return i + 'th';
    },
  },
  computed: {
    heldWithInterest: function() {
      return (this.withInterest ? 'With Interest': 'Without Interest');
    },
    rentalAmountSummary: function() {
      return this.rentalAmount ? `$${this.rentalAmount}` : '[x]';
    },
    rentalStartDateSummary: function() {
      if (this.rentalStartDate) {
        return this.rentalStartDate.toDateString();
      } else {
        return '[date]';
      }
    },
    rentalEndDateSummary: function() {
      if (this.rentalEndDate) {
        return this.rentalEndDate.toDateString();
      } else {
        return '[date]';
      }
    },
    rentDaySummary: function() {
      if (this.rentDay) {
        return this.ordinalSuffixOf(this.rentDay);
      } else {
        return '[x]';
      }
    },
  },
};
</script>
