<template>
  <div id="residential-fields" class="columns">
    <div class="column is-one-quarter">
      <b-field label="Rental Start Date">
        <b-datepicker
                v-model="rentalStartDate"
                placeholder="Click to select..."></b-datepicker>
      </b-field>
    </div>
    <div class="column is-one-quarter">
      <b-field label="Rental End Date">
        <b-datepicker
                v-model="rentalEndDate"
                placeholder="Click to select..."></b-datepicker>
      </b-field>
    </div>

    <div class="column is-one-quarter">
      <b-field label="SIN">
        <b-input v-model="socialInsuranceNumber" placeholder="123-456-789"/>
      </b-field>
    </div>
    <div class="column is-one-quarter">
      <b-field label="Driver's License Number">
        <b-input v-model="driversLicenseNumber" placeholder="123874635B"/>
      </b-field>
      <b-field class="file">
        <b-upload v-model='driversLicense'>
          <a class="button is-dark upload-button">
            <b-icon icon='upload' pack='fas' title="Upload Photo"></b-icon>
            <span>Upload Driver's License</span>
          </a>
        </b-upload>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    prefillData: Object,
  },
  data() {
    return {
      rentalStartDate: null,
      rentalEndDate: null,
      socialInsuranceNumber: '',
      driversLicenseNumber: '',
      driversLicense: null,
    };
  },
  created() {
    if (this.prefillData) {
      const {rentalStartDate, rentalEndDate, secureMeta} = this.prefillData;
      this.rentalStartDate = new Date(rentalStartDate);
      this.rentalEndDate = new Date(rentalEndDate);
      this.socialInsuranceNumber = secureMeta.residentialFields.socialInsuranceNumber;
      this.driversLicenseNumber = secureMeta.residentialFields.driversLicenseNumber;
    }
  },
};
</script>
