// TODO: This is pretty bad. PR#193 introduces a large refactor to this
// Do not change or add to this file anymore

import Vue from 'vue/dist/vue.esm';
import _ from 'lodash';
import addyData from 'utils/addy-data';

const container = 'legal-entity-form-container';

addyData.registerForLoadEvent('turbolinks:load', container);

document.addEventListener('addy-data:load', () => {
  if (document.getElementById(container)) {
    const legalEntityData = addyData.fetchData(container)['legalEntity'];

    if (legalEntityData.dateOfRecognition) {
      legalEntityData.dateOfRecognition = new Date(legalEntityData.dateOfRecognition);
    }
    if (legalEntityData.dateOfAnnualReport) {
      legalEntityData.dateOfAnnualReport = new Date(legalEntityData.dateOfAnnualReport);
    }

    new Vue({
      el: `#${container}`,
      components: {},
      data: {...legalEntityData,
        dateFormat: null,
        mailingSame: false,
        unitTypes: ['Common', 'Preferred'],
        officers: [],
        newOfficer: {},
      },
      methods: {
        removeOfficer(index) {
          this.officers.splice(index, 1);
        },
        addOfficer() {
          this.officers.push(this.newOfficer);
          this.setNewOfficerValues();
        },
        setNewOfficerValues() {
          this.mailingSame = false;
          this.newOfficer = {
            role: null,
            firstName: '',
            middleName: '',
            lastName: '',
            deliveryAddress: {
              streetAddress: '',
              city: '',
              province: '',
              country: '',
              postalCode: '',
            },
            mailingAddress: {
              streetAddress: '',
              city: '',
              province: '',
              country: '',
              postalCode: '',
            },
          };
        },
        toggleOfficerDetails(index) {
          const element = document.getElementById(`officer-${index}`);
          element.classList.toggle('is-hidden');
        },
        officerDetails(index) {
          const currentOfficer = this.officers[index];
          return currentOfficer.firstName + ' ' +
              currentOfficer.middleName + ' ' +
              currentOfficer.lastName + ' - ' +
              _.startCase(currentOfficer.role);
        },
      },
      watch: {
        mailingSame() {
          _.merge(this.newOfficer, {
            mailingAddress: {
              streetAddress: this.newOfficer.deliveryAddress.streetAddress,
              city: this.newOfficer.deliveryAddress.city,
              province: this.newOfficer.deliveryAddress.province,
              country: this.newOfficer.deliveryAddress.country,
              postalCode: this.newOfficer.deliveryAddress.postalCode,
            },
          });
        },
      },
      created() {
        if (!this.officers) {
          this.officers = [];
        }
        this.setNewOfficerValues();
      },
    });
  }
});
