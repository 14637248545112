<template>
  <div>
    <div class="columns">
      <div class="column is-10">
        <div class="title">Insurance</div>
        <div class="subtitle">{{ legalEntityName }}</div>
      </div>
      <div class="column is-2">
        <a :href="newPath()" class="button is-link is-flex flex-end">Add Insurance</a>
      </div>
    </div>
    <b-table :data="insurances" striped hoverable>
      <template slot-scope="props">
          <b-table-column field="id" label="ID" width="40">
            <a :href="showPath(props.row.id)">
              {{ props.row.id }}
            </a>
          </b-table-column>
          <b-table-column field="insurer" label="Insurer">
            {{ props.row.insurer }}
          </b-table-column>
          <b-table-column field="namedInsured" label="Named Insured">
            {{ props.row.namedInsured }}
          </b-table-column>
          <b-table-column field="amountPayable" label="Amount Payable">
            {{ props.row.amountPayable }}
           </b-table-column>
          <b-table-column field="effectiveStartDate" label="Effective Start Date">
            {{ props.row.effectiveStartDate }}
          </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
const columns = [
];

export default {
  props: ['legalEntityName', 'accountId', 'legalEntityId'],
  data: function() {
    return {
      columns: columns,
      insurances: [],
    };
  },
  created() {
    this.insurances = this.$parent.insurances;
  },
  computed: {
  },
  methods: {
    showPath: function(id) {
      return `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/insurances/${id}`;
    },
    newPath: function() {
      return `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/insurances/new`;
    },
  },
};
</script>
