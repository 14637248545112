import Vue from 'vue/dist/vue.esm';
import Fileupload from 'components/fileupload/fileupload.vue';
import addyData from 'utils/addy-data';

const container = 'account-form-container';

addyData.registerForLoadEvent('turbolinks:load', container);

document.addEventListener('addy-data:load', () => {
  if (document.getElementById(container)) {
    const memberData = addyData.fetchData(container)['members'];
    const accountData = addyData.fetchData(container)['account'];

    const account = new Vue({
      el: `#${container}`,
      components: {Fileupload},
      data: Object.assign({
        isActive: null,
        billingPlans: [
          'Free',
          'Basic',
          'Pro',
          'Enterprise',
        ],
      }, memberData, accountData),
      created() {
        this.disabledAt == null ? this.isActive = true : this.isActive = false;
      },
      watch: {
        isActive: (value) => {
          value == true ? this.disabledAt = null : this.disabledAt = 't';
        },
      },
    });
  }
});
