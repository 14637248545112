<template>
  <form>
    <div class="title">Add Insurance</div>
    <div class="subtitle">{{ legalEntityName }}</div>
    <b-field label="Insurer">
      <b-input v-model="insurer" type="text"></b-input>
    </b-field>
    <b-field label="Due Date">
      <b-input v-model="dueDate" type="date"></b-input>
    </b-field>
    <b-field label="Dwelling">
      <b-input v-model="dwelling" type="text"></b-input>
    </b-field>
    <b-field label="Named Insured">
      <b-input v-model="namedInsured" type="text"></b-input>
    </b-field>
    <b-field label="Amount Payable">
      <b-input v-model="amountPayable" type="number"></b-input>
    </b-field>
    <b-field label="Legal Liability">
      <b-input v-model="legalLiability" type="text"></b-input>
    </b-field>
    <b-field label="Detached Structures">
      <b-input v-model="detachedStructures" type="number"></b-input>
    </b-field>
    <b-field label="Effective Start Date">
      <b-input v-model="effectiveStartDate" type="date"></b-input>
    </b-field>
    <b-field label="Effective End Date">
      <b-input v-model="effectiveEndDate" type="date"></b-input>
    </b-field>
    <b-button tag="input"
              native-type="submit"
              @click.prevent="submit"
              value="Save" />
  </form>
</template>

<script>
import Axios from '../../../utils/axios';

export default {
  props: ['accountId', 'legalEntityId', 'insuranceId', 'legalEntityName'],
  data: function() {
    return {
      members: [],
      insurance: null,
      insurer: '',
      dueDate: '',
      dwelling: '',
      namedInsured: '',
      amountPayable: '',
      legalLiability: '',
      detachedStructures: '',
      effectiveStartDate: '',
      effectiveEndDate: '',
    };
  },
  created() {
    if (this.insuranceId) this.getInsurance();
  },
  computed: {
    createPath: function() {
      return `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/insurances`;
    },
    updatePath: function() {
      return `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/insurances/${this.insuranceId}`;
    },
  },
  methods: {
    getInsurance: function() {
      const {
        insurer,
        dueDate,
        dwelling,
        namedInsured,
        amountPayable,
        legalLiability,
        detachedStructures,
        effectiveStartDate,
        effectiveEndDate,
      } = this.$parent.insurance;

      this.insurer = insurer;
      this.dueDate = dueDate;
      this.dwelling = dwelling;
      this.namedInsured = namedInsured;
      this.amountPayable = amountPayable;
      this.legalLiability = legalLiability;
      this.detachedStructures = detachedStructures;
      this.effectiveStartDate = effectiveStartDate;
      this.effectiveEndDate = effectiveEndDate;
    },
    insurancePayload: function() {
      return {
        insurance: {
          insurer: this.insurer,
          dueDate: this.dueDate,
          dwelling: this.dwelling,
          namedInsured: this.namedInsured,
          amountPayable: this.amountPayable,
          legalLiability: this.legalLiability,
          detachedStructures: this.detachedStructures,
          effectiveStartDate: this.effectiveStartDate,
          effectiveEndDate: this.effectiveEndDate,
        }
      };
    },
    submit: function() {
      if(this.insuranceId) {
        Axios.put(this.updatePath, this.insurancePayload());
      } else {
        Axios.post(this.createPath, this.insurancePayload());
      }
    },
  },
};
</script>
