<template>
  <form class="box">
    <p class="is-size-4">Property Information</p>
    <b-field label="Property Name">
      <b-input v-model="name"></b-input>
    </b-field>
    <b-field label="Purchase Date">
      <b-datepicker
        v-model="purchaseDate"
        placeholder="Click to select..."
        trap-focus>
      </b-datepicker>
    </b-field>
    <b-field label="Assessed Value">
      <b-input v-model="assessedValue"></b-input>
    </b-field>
    <b-field label="Purchase Price">
      <b-input v-model="purchasePrice"></b-input>
    </b-field>
    <b-field label="Assessment Year">
      <b-input v-model="assessmentYear"></b-input>
    </b-field>
    <b-button tag="input"
      native-type="submit"
      @click.prevent="submit"
      value="Save" />
  </form>
</template>

<script>
import Axios from '../../../utils/axios';

export default {
  props: ['accountId', 'legalEntityId', 'propertyId', 'property'],
  data() {
    return {
      name: '',
      purchaseDate: null,
      assessedValue: '',
      purchasePrice: '',
      assessmentYear: '',
    };
  },
  created() {
    if (this.property) {
      this.unpackProperty();
    }
  },
  computed: {
    createPath() {
      return `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/entity_assets`;
    },
    updatePath() {
      return `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/entity_assets/${this.propertyId}`;
    },
  },
  methods: {
    unpackProperty() {
      const {name,data} = this.property;
      const {purchaseDate,assessedValue,purchasePrice,assessmentYear} = data['default'];

      this.name = name;
      if (purchaseDate) this.purchaseDate = new Date(purchaseDate);
      this.assessedValue = assessedValue;
      this.purchasePrice = purchasePrice;
      this.assessmentYear = assessmentYear;
    },
    propertyPayload() {
      return {
        property: {
          name: this.name,
          purchaseDate: this.purchaseDate,
          assessedValue: this.assessedValue,
          purchasePrice: this.purchasePrice,
          assessmentYear: this.assessmentYear,
        },
      };
    },
    submit(event) {
      if (this.propertyId) {
        Axios.put(this.updatePath, this.propertyPayload());
      } else {
        Axios.post(this.createPath, this.propertyPayload());
      }
    },
  },
};
</script>
