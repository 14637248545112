<template>
  <b-table :data="activityEntries" striped hoverable>
    <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
      <a :href="resourcePath(props.row.id)">
        {{ props.row.id }}
      </a>
    </b-table-column>
    <b-table-column field="activity" label="Type" v-slot="props">
      <i
        :class="activityIcon(props.row.activity)"
        :style="activityStyle(props.row.activity)">
      </i>
    </b-table-column>
    <b-table-column field="public" label="Public" v-slot="props">
      {{ props.row.public ? 'Yes' : 'No' }}
    </b-table-column>
    <b-table-column field="description" label="Description" v-slot="props">
      <p v-for="line in props.row.description.split('\n')" :key="line">
        {{ line }}
      </p>
    </b-table-column>
    <b-table-column field="attachment" label="Attachment" v-slot="props">
      <template v-if="props.row.attachment">
        <a :href="props.row.attachment.url"
          disposition="preview"
          target="_blank">
          <img
            v-if="props.row.attachment.type == 'image'"
            :src="props.row.attachment.url"
            :alt="props.row.attachment.name"/>
          <span
            v-if="props.row.attachment.type == 'document'">
            {{props.row.attachment.filename}}
          </span>
        </a>
      </template>
      <template v-else>
        No File Uploaded
      </template>
    </b-table-column>
  </b-table>
</template>

<script>
export default {
  props: ['accountId', 'legalEntityId'],
  data: function() {
    return {
      activityEntries: [],
    };
  },
  created() {
    this.activityEntries = this.$parent.activityEntries;
  },
  methods: {
    resourcePath(id) {
      return `/manager/accounts/${this.accountId}/legal_entities/${this.legalEntityId}/activity_entries/${id}/edit`;
    },
    activityIcon(activity) {
      // TODO: Can I add a class and a :class?
      const baseClass = 'fas';

      switch (activity) {
        case 'event':
          return `${baseClass} fa-sticky-note`;
        case 'rent_paid':
          return `${baseClass} fa-file-invoice-dollar`;
        case 'disbursement':
          return `${baseClass} fa-dollar-sign`;
        case 'partial_rent_paid':
          return `${baseClass} fa-dollar-sign`;
      };
    },
    activityStyle(activity) {
      switch (activity) {
        case 'event':
          return {
            color: 'purple',
          };
        case 'rent_paid':
          return {
            color: 'gray',
          };
        case 'disbursement':
          return {
            color: 'green',
          };
        case 'partial_rent_paid':
          return {
            color: 'orange',
          };
      };
    },
  },
};
</script>
