<template>
    <div>
        <div class="title has-text-centered">
            Create an Account
        </div>
        <b-field label="Email">
                <b-input v-model="member.email"
                         name="email"/>
        </b-field>
        <b-field label="Password">
                <b-input type="password"
                         password-reveal
                         v-model="member.password"
                         name="password"/>
        </b-field>
        <b-field label="Country">
            <b-select name="country"
                      placeholder="Country" v-model="member.country">
                <option value="" disabled>Country</option>
                <option v-for="option in Countries" :value="option.code" :key="option.code">
                    {{ option.name }}
                </option>
            </b-select>
        </b-field>
        <b-field v-if="this.member.country == 'United States'" label="State">
            <b-select name="stateOrProvince"
                      placeholder="State" v-model="member.stateOrProvince"
                      key="stately-input">
                <option value="" disabled>State</option>
                <option v-for="option in UsaStates" :value="option.code" :key="option.code">
                    {{ option.name }}
                </option>
            </b-select>
        </b-field>
        <b-field v-if="this.member.country == 'Canada'" label="Province">
            <b-select name="stateOrProvince"
                      placeholder="Province" v-model="member.stateOrProvince"
                      key="province-input">
                <option value="" disabled>Province</option>
                <option v-for="option in CanadaProvinces" :value="option.code" :key="option.code">
                    {{ option.name }}
                </option>
            </b-select>
        </b-field>
        <div v-else>
            &nbsp;
        </div>
<!--        <div class="field">-->
<!--            <b-checkbox v-model="member.subscribed">-->
<!--                Sign-up for addy newsletters and get notified of new properties.-->
<!--            </b-checkbox>-->
<!--        </div>-->
        <div class="field">
                <b-checkbox v-model="acceptedTerms">
                    Accept<a href="https://addyinvest.com/terms-of-service/"> Terms of Service</a>
                </b-checkbox>
        </div>
        <div class="actions">
            <promise-loading-button :action="createAccount"
                                    :disabled="!formValidated"
                                    class="button is-primary is-fullwidth">
                Create Account
            </promise-loading-button>

            <fail-message-modal ref="failModal" title="Oh no!"/>
        </div>
    </div>
</template>

<script>
    import CanadaProvinces from 'data/canada_provinces';
    import UsaStates from 'data/usa_states';
    import Countries from 'data/countries';
    import PromiseLoadingButton from 'components/promise-loading-button/component.vue'
    import FailMessageModal from 'components/fail-message-modal/component.vue'
    import axios from 'utils/axios';
    import FormMixins from 'utils/form-mixins';
    import queryString from 'query-string';
    import _ from 'lodash';

    export default {
        props: [],
        components: {FailMessageModal, PromiseLoadingButton},
        data: () => {
          return {
            member: {
              email: null,
              country: null,
              stateOrProvince: null,
            },
            Countries,
            UsaStates,
            CanadaProvinces,
            acceptedTerms: false,
          }
        },
        mounted() {
          const params = queryString.parse(window.location.search);
          if (_.isEmpty(params) == false) {
            if (params.email) {
              this.member.email = params.email;
            }
            if (params.country) {
              this.member.country = params.country;
            }
            this.acceptedTerms = true;
            this.$nextTick(() => {
              if (params.state_or_province) {
                this.member.stateOrProvince = params.state_or_province;
              }
            })
          }
        },
        watch: {
          "member.country"() {
            this.member.stateOrProvince = null;
            // Vue.nextTick(this.revalidate.bind(this));
          }
        },
        mixins: [FormMixins],
        methods: {
            createAccount() {
                return axios.post('/api/members',
                    {
                        member: this.member
                    }).then(this.memberCreated.bind(this))
                    .catch(this.failedXHR.bind(this));

            },
            memberCreated(xhr) {
                // this.$session.set('currentMember', xhr.data);
                // this.$router.push('/signup/unverified');
                window.location.href = '/signup/unverified';
            },
        },
        computed: {
            formValidated(){
                return this.member.email &&
                    this.member.password &&
                    this.member.country &&
                    this.member.stateOrProvince
                    && this.acceptedTerms;
            }
        }
    };
</script>
