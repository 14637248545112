import Vue from 'vue/dist/vue.esm';
import addyData from 'utils/addy-data';
import CsrTop from '../../../components/manager/csr/csr_top';
import CsrListing from '../../../components/manager/csr/csr_listing';

const container = 'manager-csr-index';
addyData.registerForLoadEvent('turbolinks:load', container);

document.addEventListener('addy-data:load', () => {
  if (document.getElementById(container)) {
    new Vue({
      el: `#${container}`,
      components: {CsrListing, CsrTop},
      data: function() {
        return {certificates: addyData.fetchData(container)['certificates']};
      },
    });
  }
});
