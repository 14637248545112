<template>
    <div>
        <div class="search-wrapper">
            <b-input type="text" v-model="search" placeholder="Search email.."/>
        </div>
        <table id="member-search-table">
            <thead>
                <tr>
                    <th>Email</th>
                    <th class="has-text-centered-tablet">First Name</th>
                    <th class="has-text-centered-tablet">Middle Name</th>
                    <th class="has-text-centered-tablet">Last Name</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="member in filteredList">
                    <td class="members-email"><a v-bind:href="'/admin/members/' + member.id">{{member.email}}</a></td>
                    <td class="has-text-centered-tablet">{{member.legalGivenName}}</td>
                    <td class="has-text-centered-tablet">{{member.legalMiddleName}}</td>
                    <td class="has-text-centered-tablet">{{member.legalFamilyName}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        props:{
            memberData: Array
        },
        data() {
            return {
                search: '',
                memberSearchData: this.memberData
            }
        },
        computed: {
            filteredList() {
                return this.memberSearchData.filter(member => {
                    return member.email.toLowerCase().includes(this.search.toLowerCase())
                })
            }
        }
    };
</script>
<style>
    #member-search-table tr:nth-child(even){background-color: #f2f2f2;}
    #member-search-table tr:hover:nth-child(n+2) {background-color: #ddd;}

    .search-wrapper {
        margin-bottom: 15px;
    }
    .members-email {
        overflow:hidden;
        white-space:nowrap;
        text-overflow: ellipsis;
    }
</style>