<template>
  <form @submit.prevent="submit">
    <div class="title">
      Log in
    </div>
    <div class="subtitle">
      New to addy?&nbsp;
      <a :href="signupPath" class="has-text-primary">Sign up now &gt;</a>
    </div>
    <b-field label="Email">
      <b-input v-model="login.email"
               name="email"/>
    </b-field>
    <b-field label="Password">
      <b-input type="password"
               password-reveal
               v-model="login.password"
               @keyup.enter.native="submit"
               name="password"/>
    </b-field>
    <promise-loading-button class="has-text-primary" :action="resetPassword">
      Forgot Password?
    </promise-loading-button>
    <div class="actions">
      <promise-loading-button ref="loginButton"
                              :action="loginInvestor"
                              class="button is-primary is-fullwidth">
        Log in
      </promise-loading-button>

      <fail-message-modal ref="failModal" title="Login Failed"/>
      <fail-message-modal ref="resetPasswordFailed" title="Reset Password Failed"/>
      <fail-message-modal ref="resetPasswordModal" title="Password Reset"/>
    </div>
  </form>
</template>

<script>
import FormMixins from 'utils/form-mixins';
import {redirectTo} from 'utils/verification_utils';
import axios from 'utils/axios';
import PromiseLoadingButton from 'components/promise-loading-button/component.vue';
import FailMessageModal from 'components/fail-message-modal/component.vue';
import _ from 'lodash';
import queryString from 'query-string';

export default {
  mixins: [FormMixins],
  components: {PromiseLoadingButton, FailMessageModal},
  data() {
    return {login: {email: null, password: null}, returnToPath: null};
  },
  mounted() {
    const params = queryString.parse(window.location.search);
    if (_.isEmpty(params) == false) {
      if (params.email) {
        this.login.email = params.email;
      }
    }
  },
  created() {
    if (this.$route.query.returnTo) {
      this.returnToPath = this.$route.query.returnTo;
    }
  },
  computed: {
    signupPath() {
      let returnQuery = '';
      if (this.returnToPath) {
        returnQuery = `?fromPage=${this.returnToPath}`;
      }
      return `/signup${returnQuery}`;
    },
  },
  methods: {
    // maybe a bit of a hack, this actually sets a rails session
    // which logs you into the whole app
    // a real api would give you a token or something.
    submit() {
      this.$refs.loginButton.click();
    },
    loginInvestor() {
      return axios.post('/login',
          {
            login: this.login,
          }).then(this.loginSuccess.bind(this))
          .catch(this.failedXHR.bind(this));
    },
    resetPassword() {
      if ( !this.login.email ) {
        this.$refs.resetPasswordFailed.show('Please input an email!');
        return Promise.reject(false);
      }
      return axios.post('/auth/reset', {email: this.login.email} ).then(
          () => {
            this.$refs.resetPasswordModal.show('Password Reset! Please check your email for instructions.');
          },
      ).catch(this.passwordResetFailure.bind(this));
    },
    loginSuccess() {
      redirectTo(this.redirectPath());
    },
    redirectPath() {
      return this.returnToPath || '/dashboard';
    },
    passwordResetFailure(xhr) {
      if (xhr.response && xhr.response.data && xhr.response.data.message) {
        if (xhr.response.data.message.includes('invalid_query_string')) {
          this.$refs.failModal.show('The format of the email provided is incorrect. Please try again.');
        } else {
          this.$refs.failModal.show(xhr.response.data.message);
        }
      } else {
        this.$refs.failModal.show('An unknown error occurred and the developers have been notified.');
        throw xhr;// might not actually be an xhr
      }
    },
  },
};
</script>
