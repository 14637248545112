import Vue from 'vue/dist/vue.esm'
import DateTimeFormatter from 'utils/date-time-formatter';
import addyData from 'utils/addy-data';

const container = 'member-edit-container';

addyData.registerForLoadEvent('turbolinks:load', container);

document.addEventListener('addy-data:load', () => {
  if (document.getElementById(container)) {
    const memberData = addyData.fetchData(container)['member'];
    const contactData = addyData.fetchData(container)['contact'];

    // Replace - with / so that JS converts the date correctly (Only applies to data type 'date' not 'datetime')
    if (contactData.birthdate) {
      contactData.birthdate = new Date(contactData.birthdate.replace('-', '/'));
    }

    new Vue({
      el: `#${container}`,
      mixins: [DateTimeFormatter],
      data: Object.assign(contactData, memberData),
      methods: {},
    });
  }
});
